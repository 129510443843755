export const environment = {
  production: true,
  system : {
    id: 'shop online'
  },

  firebase: {
    apiKey: 'AIzaSyBTdT7avWR-DGGfirv9hTdQCdacNJw7DA4',
    authDomain: 'power-bolt.firebaseapp.com',
    databaseURL: 'https://power-bolt.firebaseio.com',
    projectId: 'power-bolt',
    storageBucket: 'power-bolt.appspot.com',
    messagingSenderId: '704343181550'
  }

};
