import { Component, OnInit } from '@angular/core';
import { UtilService } from './../../util.service';
import * as $ from 'jquery';

declare function initial(isDevMode: boolean): any;

@Component({
  selector: 'app-pay-time',
  templateUrl: './pay-time.component.html'
})
export class ReportPayTimeComponent implements OnInit {

  constructor(
    private util: UtilService
  ) { }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    initial(this.util.isDevMode);
  }

}
