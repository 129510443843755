import { Component, NgZone, Renderer2, AfterViewChecked } from '@angular/core';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UtilService } from './util.service';

declare function initial(isDevMode: boolean): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewChecked {
  title = 'API';

  constructor(
    private afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    public util: UtilService,
    private db: AngularFireDatabase,
    private fns: AngularFireFunctions
  ) {

    this.util.subscribeLanguage();
    this.util.subscribeScreen();
    this.util.subscribeSystemInfo();

    this.afAuth.auth.onAuthStateChanged((user) => {
      if (user) {
        util.member.displayName = user.displayName;
        util.member.photoURL = `${user.photoURL}?type=normal`;
        util.member.uid = user.uid;
        util.isLogin = true;
        renderer.removeClass(document.body, 'body-login');
        renderer.addClass(document.body, `skin-${this.util.isDevMode ? 'red' : 'blue'}`);

        let page = localStorage.getItem('page');
        if (page === undefined || page === 'login') {
          page = 'profile';
        }
        ngZone.run(() => router.navigate([page])).then();
        this.subscribeMemberInfo(user.uid);

      } else {
        util.member.displayName = 'Unknown';
        util.member.photoURL = 'assets/img/avatar5.png';
        util.member.uid = '';
        util.isLogin = false;
        renderer.addClass(document.body, 'body-login');
        renderer.removeClass(document.body, `skin-${this.util.isDevMode ? 'red' : 'blue'}`);
        ngZone.run(() => router.navigate(['login'])).then();
      }
    });

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        util.page.url = event.url.substr(1);
        util.page.main = util.page.url.split('/')[0];
        util.page.sub = util.page.url.split('/')[1];
        util.page.key = util.page.url.replace(/\//g, '_');
        if (util.page.sub === undefined) {
          util.page.sub = '';
        }
        if (util.page.main.trim() === '') {
          util.page.main = 'dashboard';
        }
        localStorage.setItem('page', util.page.sub !== undefined && util.page.sub !== '' ? util.page.url : util.page.main);
      }
    });

  }

  ngAfterViewChecked() {
    window.dispatchEvent(new Event('resize'));
    initial(this.util.isDevMode);
  }

  logout() {
    this.renderer.addClass(document.body, 'body-login');
    this.renderer.removeClass(document.body, `skin-${this.util.isDevMode ? 'red' : 'blue'}`);
    this.afAuth.auth.signOut();
  }

  subscribeMemberInfo(uid) {
    this.db.object(`member/info/${uid}`).valueChanges().subscribe(
      (res: any) => {
        if (res == null ) {
          if (this.util.member.id !== undefined) {
            delete this.util.member.id;
            this.logout();
          }
        } else {
          this.util.member = res;
          this.db.object(`system/member/${this.util.system.id}/${res.id}`).valueChanges().subscribe(
            (resp: any) => {
              if (resp !== null) {
                Object.assign(this.util.member, resp);
                if ( this.util.member.shop !== undefined) {
                  this.db.object(`shop/member/${this.util.system.id}/${this.util.member.shop[this.util.system.id].selected.type}` +
                    `/${this.util.member.shop[this.util.system.id].selected.id}/${res.id}`).valueChanges().subscribe(
                    (rsp: any) => {
                      if (resp !== null) {
                        this.util.member.shop.member = rsp;
                        this.util.subscribeMemberScreen();
                        this.util.subscribeMemberShop();
                      }
                    },
                    error => {
                      this.util.alertError(error, 5000);
                    }
                  );
                } else {
                  this.util.subscribeMemberScreen();
                }
              }
            },
            error => {
              this.util.alertError(error, 5000);
            }
          );

          if (res.system === undefined || (res.system !== undefined && res.system[this.util.system.id] === undefined)) {
            this.fns.httpsCallable('API')({ method: 'memberSystem', action: 'add', id: this.util.system.id })
            .toPromise()
            .then(resp => {
              if (!resp.success) { this.util.alertError(resp.error, 5000); }
            });
          }
        }
      },
      error => {
        if (error.message.indexOf('permission_denied') === -1) {
          this.util.alertError(error, 5000);
        }
      }
    );
  }

}
