import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilService } from './../../util.service';
import { FormInput } from './../../formInput';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare function initial(isDevMode: boolean): any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html'
})
export class SettingPaymentComponent implements OnInit, OnDestroy {

  constructor(
    private util: UtilService,
    private fns: AngularFireFunctions,
    private smartModal: NgxSmartModalService,
    private db: AngularFireDatabase
  ) {
    this.input.info = {
      bank: '',
      name: '',
      number: '',
      branch: ''
    };
  }

  input = new FormInput();
  inputType = '';
  action = '';
  currentData: any = {};
  bankInfo: any = {};
  payment: any = {};
  isCheck: any = {
    bank: false,
    promptpay: false,
    paypal: false
  };
  hasCheck: any = {
    bank: false,
    promptpay: false,
    paypal: false
  };

  subscriptionMemberType: any;
  subscriptionMemberShop: any;
  subscriptionBank: any;

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    initial(this.util.isDevMode);
    this.getBankInfo('Thailand');
    this.subscribeMemberChange();
  }

  ngOnDestroy() {
    this.subscriptionMemberType.unsubscribe();
    this.subscriptionMemberShop.unsubscribe();
    this.subscriptionBank.unsubscribe();
  }

  // ตรวจสอบการเปลี่ยนสิทธิ์การใช้งานใหม่
  subscribeMemberChange() {
    this.subscriptionMemberType = this.db.object(`system/member/${this.util.system.id}/${this.util.member.id}/selected/memberType`)
    .valueChanges().subscribe((res: any) => {
      if (res !== 'shop') { // ถ้าสิทธิ์ไม่ใช่ Shop
        this.subscriptionBank.unsubscribe(); // Unsubscribe ข้อมูลช่องทางการชำระเงิน
      } else { // ถ้าสิทธิ์เป็น Shop
        this.subscriptionMemberShop = this.db.object(`member/info/${this.util.member.uid}/shop/${this.util.system.id}/selected`)
        .valueChanges().subscribe((resp: any) => {
          setTimeout(() => {
            this.subscribeBank();
          }, this.subscriptionBank === undefined ? 1 : 500); // รอโหลดข้อมูลต่างๆ ให้ครบก่อน
        });
      }
    });
  }

  subscribeBank() {
    this.payment = {};
    this.subscriptionBank = this.db.object(`payment/info/${this.util.system.id}/shop/` +
        `${this.util.member.shop[this.util.system.id].selected.type}/` +
        `${this.util.member.shop[this.util.system.id].selected.id}`).valueChanges().subscribe(
      (res: any) => {
        if (res !== null) {
          this.payment = res;

          if (res.promptpay === undefined) {
            this.payment.promptpay = {
              active: false,
              name: '',
              number: '',
              isNew: true
            };
          }
          this.input.info.promptpay = JSON.parse(JSON.stringify(this.payment.promptpay));

          if (res.paypal === undefined) {
            this.payment.paypal = {
              active: false,
              email: '',
              fee: '',
              feeType: '',
              isNew: true
            };
          }
          this.input.info.paypal = JSON.parse(JSON.stringify(this.payment.paypal));
        } else {
          this.initialDefaultData();
        }
      },
      error => {
        this.initialDefaultData();
      }
    );
  }

  initialDefaultData() {
    this.payment = {
      promptpay: {
        active: false,
        name: '',
        number: '',
        isNew: true
      },
      paypal: {
        active: false,
        email: '',
        fee: '',
        feeType: '',
        isNew: true
      }
    };
    this.input.info.promptpay = JSON.parse(JSON.stringify(this.payment.promptpay));
    this.input.info.paypal = JSON.parse(JSON.stringify(this.payment.paypal));
  }

  getBankInfo(country) {
    this.fns.httpsCallable('API')({ method: 'bank', action: 'infoByCountry', country: `${country}` })
    .toPromise()
    .then(res => {
      if (res.success) {
        this.bankInfo = res.result;
      } else {
        this.bankInfo = {};
        this.util.alertError(res.error, 5000);
      }
    });
  }

  addData(type) {
    this.inputType = type;
    this.action = 'add';
    this.input.info.bank = '';
    this.input.info.name = '';
    this.input.info.number = '';
    this.input.info.branch = '';
    this.currentData = { bank: this.input.info.bank, name: this.input.info.name,
      number: this.input.info.number, branch: this.input.info.branch };
    this.smartModal.getModal('inputModal').open();
  }

  editData(type, index) {
    this.inputType = type;
    this.action = 'update';
    this.input.info.bank = this.payment[type][index].bank;
    this.input.info.name = this.payment[type][index].name;
    this.input.info.number = index;
    this.input.info.branch = this.payment[type][index].branch;
    this.currentData = { bank: this.input.info.bank, name: this.input.info.name,
      number: this.input.info.number, branch: this.input.info.branch };
    this.smartModal.getModal('inputModal').open();
  }

  enable(type, active) {
    this.input.info[type].active = active;
    this.validateData(type);
  }

  setFeeType(type) {
    this.input.info.paypal.feeType = type;
    this.validateData('paypal');
  }

  validateData(type) {
    let hasChange = false;
    let arr = [];
    if (type === 'promptpay') {
      arr = ['active', 'name', 'number'];
    } else if (type === 'paypal') {
      arr = ['active', 'email', 'fee', 'feeType'];
    }
    arr.forEach(a => {
      if ( this.payment[type][a] !== this.input.info[type][a] ) {
        hasChange = true;
      }
    });
    this.input.info[type].hasChange = hasChange;
  }

  resetData(type) {
    let arr = [];
    if (type === 'promptpay') {
      arr = ['active', 'name', 'number'];
    } else if (type === 'paypal') {
      arr = ['active', 'email', 'fee', 'feeType'];
    }
    arr.forEach(a => {
      this.input.info[type][a] = this.payment[type][a];
    });
    this.input.info[type].hasChange = false;
  }

  prepareAndSaveData(type) {
    let arr = [];
    if (type === 'promptpay') {
      arr = ['active', 'name', 'number'];
    } else if (type === 'paypal') {
      arr = ['active', 'email', 'fee', 'feeType'];
    }
    arr.forEach(a => {
      this.input.info[a] = this.input.info[type][a];
      this.currentData[a] = this.payment[type][a];
    });

    this.action = this.payment[type].isNew ? 'add' : 'update';
    this.inputType = type;
    this.saveData();
    this.input.info[type].hasChange = false;
    delete this.payment[type].isNew;
  }

  saveData() {
    const json: any = {
      method: 'payment',
      action: this.action,
      type: this.inputType
    };

    let takeAction = false;
    const keys = Object.keys(this.currentData);
    keys.forEach(key => { // ตรวจสอบว่าข้อมูลที่เพิ่มหรือแก้ไข มีการเปลี่ยนแปลงหรือไม่
      if (this.currentData[key] !== this.input.info[key]) {
        json[key] = this.input.info[key];
        takeAction = true;
      }
    });
    if (this.action === 'update') {
      if (this.inputType === 'bank' || this.inputType === 'promptpay') {
        json.number = this.input.info.number;
      } else {
        json.email = this.input.info.email;
      }
    }

    this.smartModal.getModal('inputModal').close();

    if (takeAction) {
      this.fns.httpsCallable('API')(json)
      .toPromise()
      .then(res => {
        if (!res.success) { this.util.alertError(res.error, 5000); }
      });
    }
  }

  selectData(type) {
    this.isCheck[type] = !this.isCheck[type];
    this.hasCheck[type] = this.isCheck[type];
    const keys = Object.keys(this.payment[type]);
    keys.forEach(key => {
      this.payment[type][key].checked = this.isCheck[type];
    });
  }

  checkData(type) {
    let count = 0;
    const keys = Object.keys(this.payment[type]);
    keys.forEach(key => {
      if (this.payment[type][key].checked !== undefined && this.payment[type][key].checked) { count++; }
    });
    this.hasCheck[type] = count > 0;

    if (keys.length === count) {
      this.isCheck[type] = true;
    }
    if (count === 0) {
      this.isCheck[type] = false;
    }
  }

  removeData(type) {
    this.inputType = type;
    this.action = 'remove';
    this.smartModal.getModal('confirmModal').open();
  }

  removeSelectedData() {

    this.smartModal.getModal('confirmModal').close();

    const keys = Object.keys(this.payment[this.inputType]);
    keys.forEach(key => {

      const json: any = {
        method: 'payment',
        action: this.action,
        type: this.inputType
      };

      if (this.inputType === 'bank' || this.inputType === 'promptpay') {
        json.number = key;
      } else {
        json.email = this.payment[this.inputType][key].email;
      }

      if (this.payment[this.inputType][key].checked !== undefined && this.payment[this.inputType][key].checked) {
        this.fns.httpsCallable('API')(json)
        .toPromise()
        .then(res => {
          if (res.success) {
            this.hasCheck[this.inputType] = false;
          } else { this.util.alertError(res.error, 5000); }
        });
      }
    });
  }

}
