import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilService } from './../../util.service';
import { AngularFireDatabase } from '@angular/fire/database';

declare function initial(isDevMode: boolean): any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class AdminUserComponent implements OnInit, OnDestroy {

  constructor(
    private util: UtilService,
    private db: AngularFireDatabase
  ) { }

  developer: any = null;
  developerSubscription: any;

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    initial(this.util.isDevMode);
    this.subscribeDeveloper();
  }

  ngOnDestroy() {
    this.developerSubscription.unsubscribe();
  }

  subscribeDeveloper() {
    this.developerSubscription = this.db.object(`system/developer/${this.util.system.id}`).valueChanges().subscribe(
      (res: any) => {
        if (res !== null) {
          this.developer = res;
        }
      },
      error => {
        this.util.alertError(error, 5000);
      }
    );
  }

  activate(id) {
    const active = !this.developer[id].active;
    this.db.object(`system/developer/${this.util.system.id}/${id}/active`).set(active);
    this.db.object(`system/member/${this.util.system.id}/${id}/memberType/developer`).set(active);
  }

}
