import { Component, OnInit, Renderer2 } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { UtilService } from '../util.service';
import { auth } from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  constructor(
    private afAuth: AngularFireAuth,
    public util: UtilService,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'body-login');
    this.renderer.removeClass(document.body, `skin-${this.util.isDevMode ? 'red' : 'blue'}`);
  }

  facebookLogin() {
    this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider());
  }

  githubLogin() {
    this.afAuth.auth.signInWithPopup(new auth.GithubAuthProvider());
  }

}
