import { Component, OnInit } from '@angular/core';
import { UtilService } from './../../util.service';
import { FormInput } from './../../formInput';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare function initial(isDevMode: boolean): any;

@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html'
})
export class AdminScreenComponent implements OnInit {

  constructor(
    private util: UtilService,
    private fns: AngularFireFunctions,
    private smartModal: NgxSmartModalService
  ) { }

  input = new FormInput();
  inputType = '';
  action = '';
  currentData: any = {};
  isCheck: any = {
    section: false,
    parent: false,
    child: false
  };
  hasCheck: any = {
    section: false,
    parent: false,
    child: false
  };

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    initial(this.util.isDevMode);
  }

  addData(type) {
    this.inputType = type;
    this.action = 'add';
    this.input.id = '';
    this.input.icon = '';
    this.input.name = '';
    this.input.link = '';
    this.input.parent = '';
    this.input.section = '';
    this.currentData = { id: this.input.id, icon: this.input.icon, name: this.input.name,
      link: this.input.link, parent: this.input.parent, section: this.input.section };
    this.smartModal.getModal('inputModal').open();
  }

  editData(type, index) {
    this.inputType = type;
    this.action = 'update';
    this.input.id = this.util.screen[type][index].id;
    this.input.icon = this.util.screen[type][index].icon;
    this.input.name = this.util.screen[type][index].name;
    this.input.link = this.util.screen[type][index].link !== undefined ? this.util.screen[type][index].link : '';
    this.input.parent = this.util.screen[type][index].parent !== undefined ? this.util.screen[type][index].parent : '';
    this.input.section = this.util.screen[type][index].section !== undefined ? this.util.screen[type][index].section : '';
    this.currentData = { id: this.input.id, icon: this.input.icon, name: this.input.name,
      link: this.input.link, parent: this.input.parent, section: this.input.section };
    this.smartModal.getModal('inputModal').open();
  }

  saveData() {
    const json: any = {
      method: 'screen',
      action: this.action,
      type: this.inputType
    };

    let takeAction = false;
    const keys = Object.keys(this.currentData);
    keys.forEach(key => { // ตรวจสอบว่าข้อมูลที่เพิ่มหรือแก้ไข มีการเปลี่ยนแปลงหรือไม่
      if (this.currentData[key] !== this.input[key]) {
        json[key] = this.input[key];
        takeAction = true;
      }
    });
    if (this.action === 'add') {
      json.level = this.util.screen[this.inputType] === undefined ? 0 : this.util.screen[this.inputType].length;
    } else if (this.action === 'update') {
      json.id = this.input.id;
    }

    this.smartModal.getModal('inputModal').close();

    if (takeAction) {
      this.fns.httpsCallable('API')(json)
      .toPromise()
      .then(res => {
        if (!res.success) { this.util.alertError(res.error, 5000); }
      });
    }
  }

  swapLevel(type, value, index) {
    this.util.screen[type][index].level = index + value;
    this.util.screen[type][index + value].level = index;
    this.action = 'update';

    this.fns.httpsCallable('API')({ method: 'screen', action: this.action, type: `${type}`,
      id: this.util.screen[type][index].id, level: index + value })
    .toPromise()
    .then(res => {
      if (!res.success) { this.util.alertError(res.error, 5000); }
    });

    this.fns.httpsCallable('API')({ method: 'screen', action: this.action, type: `${type}`,
      id: this.util.screen[type][index + value].id, level: index })
    .toPromise()
    .then(res => {
      if (!res.success) { this.util.alertError(res.error, 5000); }
    });
  }

  selectData(type) {
    this.isCheck[type] = !this.isCheck[type];
    this.hasCheck[type] = this.isCheck[type];
    this.util.screen[type].forEach(element => {
      element.checked = this.isCheck[type];
    });
  }

  checkData(type) {
    let count = 0;
    this.util.screen[type].forEach(element => {
      if (element.checked) { count++; }
    });
    this.hasCheck[type] = count > 0;

    if (this.util.screen[type].length === count) {
      this.isCheck[type] = true;
    }
    if (count === 0) {
      this.isCheck[type] = false;
    }
  }

  removeData(type) {
    this.inputType = type;
    this.smartModal.getModal('confirmModal').open();
  }

  removeSelectedData() {
    this.smartModal.getModal('confirmModal').close();
    this.hasCheck[this.inputType] = false;
    this.util.screen[this.inputType].forEach(element => {
      if (element.checked) {
        this.fns.httpsCallable('API')({ method: 'screen', action: 'remove', type: this.inputType, id: element.id })
        .toPromise()
        .then(res => {
          if (!res.success) { this.util.alertError(res.error, 5000); }
        });
      }
    });
  }

}
