import { Component, OnInit } from '@angular/core';
import { UtilService } from './../../util.service';
import * as $ from 'jquery';

declare function initial(isDevMode: boolean): any;

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html'
})
export class ReportSalesComponent implements OnInit {

  constructor(
    private util: UtilService
  ) { }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    initial(this.util.isDevMode);
  }

}
