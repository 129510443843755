export class FormInput {

  public id;
  public name;
  public icon;
  public link;
  public level;
  public parent;
  public section;
  public info: any;

  constructor(
  ) { }
}
