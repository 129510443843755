import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';

import { ChatComponent } from './chat/chat.component';
import { SalesComponent } from './sales/sales.component';
import { CustomerComponent } from './customer/customer.component';

import { ProductInfoComponent } from './product/info/info.component';
import { ProductInventoryComponent } from './product/inventory/inventory.component';

import { ReportSalesComponent } from './report/sales/sales.component';
import { ReportProfitComponent } from './report/profit/profit.component';
import { ReportPayTimeComponent } from './report/pay-time/pay-time.component';
import { ReportTransportationComponent } from './report/transportation/transportation.component';
import { ReportPaymentComponent } from './report/payment/payment.component';

import { SettingShopComponent } from './setting/shop/shop.component';
import { SettingPaymentComponent } from './setting/payment/payment.component';
import { SettingTransportationComponent } from './setting/transportation/transportation.component';
import { SettingPermissionComponent } from './setting/permission/permission.component';
import { SettingMessageComponent } from './setting/message/message.component';
import { SettingBillComponent } from './setting/bill/bill.component';

import { AdminShopComponent } from './admin/shop/shop.component';
import { AdminScreenComponent } from './admin/screen/screen.component';
import { AdminSystemComponent } from './admin/system/system.component';
import { AdminUserComponent } from './admin/user/user.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'sales', component: SalesComponent },
  { path: 'customer', component: CustomerComponent },
  { path: 'product/product-information', component: ProductInfoComponent },
  { path: 'product/inventory', component: ProductInventoryComponent },
  { path: 'report/total-sales', component: ReportSalesComponent },
  { path: 'report/profit', component: ReportProfitComponent },
  { path: 'report/pay-time', component: ReportPayTimeComponent },
  { path: 'report/transportation-time', component: ReportTransportationComponent },
  { path: 'report/payment-rate', component: ReportPaymentComponent },
  { path: 'settings/shop-information', component: SettingShopComponent },
  { path: 'settings/payment-channel', component: SettingPaymentComponent },
  { path: 'settings/transportation', component: SettingTransportationComponent },
  { path: 'settings/user-permission', component: SettingPermissionComponent },
  { path: 'settings/message', component: SettingMessageComponent },
  { path: 'settings/bill', component: SettingBillComponent },
  { path: 'shop', component: AdminShopComponent },
  { path: 'system-screen', component: AdminScreenComponent },
  { path: 'system', component: AdminSystemComponent },
  { path: 'user', component: AdminUserComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
