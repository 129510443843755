import { Injectable, isDevMode } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class UtilService {

  isLogin = false;
  isLoaded = false;
  isDevMode = isDevMode();
  local = localStorage.getItem('local') == null ? 'th' : localStorage.getItem('local');

  system = environment.system;
  systemInfo: any;
  shopInfo: any = {};

  language: any = {};
  task: any = {
    language: {}
  };
  screen: any = {};
  alert: any = {
    show: false,
    type: '',
    message: '',
    count: 0
  };

  objectKeys = Object.keys;

  page = {
    url: '',
    main: '',
    sub: '',
    key: ''
  };

  member: any = {
    uid: null,
    displayName: 'Unknown',
    photoURL: 'assets/img/avatar5.png'
  };

  constructor(
    private fns: AngularFireFunctions,
    private db: AngularFireDatabase
  ) { }

  alertError(error, delay) {
    this.showAlert('error', `${error.message}${error.info !== undefined ? ` ( ${error.info} )` : ''}`, delay);
  }

  alertSuccess(message, delay) {
    this.showAlert('success', message, delay);
  }

  alertInfo(message, delay) {
    this.showAlert('info', message, delay);
  }

  private showAlert(type, message, delay) {
    this.alert.show = true;
    this.alert.type = type;
    this.alert.message = message;
    this.alert.count++;
    setTimeout(() => {
      this.alert.count--;
      this.hideAlert(delay);
    }, delay);
  }

  private hideAlert(delay) {
    if (this.alert.count <= 0) {
      this.alert = {
        show: false,
        type: '',
        message: '',
        count: 0
      };
    } else {
      setTimeout(() => {
        this.alert.count--;
        this.hideAlert(delay);
      }, delay);
    }

  }

  message(message: string) {
    const returnMessage = message;
    message = message.toLowerCase().replace(/-/g, ' ');
    if (this.language[this.local] !== undefined && this.language[this.local][message]) {
      return this.language[this.local][message];
    } else {
      this.task.language[message] = true;
      return returnMessage;
    }
  }

  addLanguage(message) {
    if ( this.language[this.local] === undefined ) {
      setTimeout(() => {
        this.addLanguage(message);
      }, 5000);
    } else {
      if ( this.language[this.local][message.toLowerCase()] !== undefined ) {
        delete this.task.language[message];
      } else if ( this.task.language[message] !== undefined ) {
        this.fns.httpsCallable('API')({ method: 'language', action: 'add',
        text: message, local: this.local }).toPromise().then(res => {
        });
      }
    }
  }

  changeLocal(local) {
    this.local = local;
    localStorage.setItem('local', local);
    this.subscribeLanguage();
  }

  subscribeLanguage() {
    this.db.object(`language/info/${this.local}`).valueChanges().subscribe(
      (res: any) => {
        if (res !== null) {
          this.language[this.local] = res;
          this.isLoaded = true;
          const keys = Object.keys(this.task.language);
          if ( keys.length > 0 ) {
            keys.forEach(key => {
              this.addLanguage(key);
            });
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  subscribeScreen() {
    this.db.object(`screen/info/${this.system.id}`).valueChanges().subscribe(
      (res: any) => {
        if (res !== null) {
          const keys = Object.keys(res);
          keys.forEach(key => {
            const childs = Object.keys(res[key]);
            const arr = [];
            childs.forEach(child => {
              arr.push( Object.assign({ id: child, checked: false }, res[key][child]) );
            });
            arr.sort( (a, b) => {
              return a.level - b.level;
            });
            this.screen[key] = arr;
          });
          this.screen.raw = res;
        }
      },
      error => {
        this.alertError(error, 5000);
      }
    );
  }

  subscribeMemberScreen() {
    let path = `screen/permission/${this.system.id}/${this.member.selected.memberType}`;
    if (this.member.selected.memberType === 'shop' && this.member.shop !== undefined) {
      path = `screen/permission/${this.system.id}/shop/${this.member.shop[this.system.id].selected.type}` +
      `/${this.member.shop[this.system.id].selected.id}/${this.member.shop.member.selected.memberType}`;
    }

    this.db.object(path).valueChanges().subscribe(
      (res: any) => {
        this.prepareScreen(res !== null ? res : {});
      },
      error => {
        /*this.alertError(error, 5000);*/
      }
    );
  }

  subscribeMemberShop() {
    if (this.member.shop[this.system.id] !== undefined && this.member.shop[this.system.id].list !== undefined) {
      const types = Object.keys(this.member.shop[this.system.id].list);
      types.forEach(type => {
        if ( this.shopInfo[type] === undefined ) { this.shopInfo[type] = {}; }
        const ids = Object.keys(this.member.shop[this.system.id].list[type]);
        ids.forEach(id => {
          if ( this.shopInfo[type][id] === undefined ) {
            this.db.object(`shop/info/${this.system.id}/${type}/${id}`).valueChanges().subscribe(
              (res: any) => {
                if (res !== null) {
                  this.shopInfo[type][id] = {
                    active: res.active,
                    name: res.name,
                    owner: res.owner,
                  };
                }
              },
              error => {
                /*this.alertError(error, 5000);*/
              }
            );
          }
        });
      });
    }
  }

  subscribeSystemInfo() {
    this.db.object(`system/info/${this.system.id}`).valueChanges().subscribe(
      (res: any) => {
        if (res !== null) {
          this.systemInfo = res;
          if (res.language !== undefined) {
            const arr = [];
            const keys = Object.keys(res.language);
            keys.forEach(key => {
              arr.push( Object.assign({ id: key, checked: false }, res.language[key]) );
            });
            arr.sort( (a, b) => {
              return a.level - b.level;
            });
            this.systemInfo.language = arr;
          }
        }
      },
      error => {
        this.alertError(error, 5000);
      }
    );
  }

  prepareScreen(memberScreen) {
    const res = this.screen !== undefined && this.screen.raw !== undefined ? this.screen.raw : {};
    let arr = [];
    const section = {};
    const parent = {};
    const count: any = {
      section: {},
      parent: {}
    };

    let keys = [];
    if (res.section !== undefined) {
      keys = Object.keys(res.section);
      keys.forEach(key => {
        section[key] = (res.section[key].level + 1 ) * 1000;
        count.section[key] = 0;
        arr.push( Object.assign({
          id: key,
          type: 'section',
          sort: section[key]
        }, res.section[key]) );
      });
    }

    if (res.parent !== undefined) {
      keys = Object.keys(res.parent);
      keys.forEach(key => {
        parent[key] = section[res.parent[key].section] + ((res.parent[key].level + 1 ) * 100);
        count.parent[key] = 0;
        arr.push( Object.assign({
          id: key,
          type: 'parent',
          sort: parent[key]
        }, res.parent[key]) );
      });
    }

    if (res.child !== undefined) {
      keys = Object.keys(res.child);
      keys.forEach(key => {
        if ( memberScreen[key] !== undefined ) {
          if ( res.child[key].section !== undefined ) { count.section[res.child[key].section]++; }
          if ( res.child[key].parent !== undefined ) { count.parent[res.child[key].parent]++; }
          arr.push( Object.assign({
            id: key,
            type: 'child',
            sort: res.child[key].parent !== undefined ?
              parent[res.child[key].parent] + res.child[key].level + 1 :
              section[res.child[key].section] + res.child[key].level + 1
          }, res.child[key]) );
        }
      });
    }

    arr.sort( (a, b) => {
      return a.sort - b.sort;
    });

    let screen = [];
    let allowedLink = ['profile'];
    let page: any = {};
    let index = -1;
    arr.forEach(a => {
      if ( ((a.type === 'section' || a.type === 'parent') && count[a.type][a.id] > 0) || (a.type === 'child' && a.parent === undefined)) {
          index = screen.length;
          screen.push(a);
      } else if (a.type === 'child' && a.parent !== undefined) {
        if (screen[index].child === undefined) {
          screen[index].child = [];
          screen[index].linkList = [];
        }
        screen[index].child.push(a);
        screen[index].linkList.push(a.link);
      }
      if (a.link !== undefined) {
        allowedLink.push(a.link);
        page[a.link.replace(/\//g, '_')] = memberScreen[a.id];
      }
    });

    this.screen.menu = screen;
    this.screen.allowed = allowedLink;
    this.screen.permission = page;
    arr = [];
    screen = [];
    allowedLink = [];
    page = {};
  }

  changeMemberType(type) {
    if (this.member.memberType[type]) {
      this.fns.httpsCallable('API')({ method: 'memberType', action: 'change',
        memberType: type }).toPromise().then(res => {
          if (!res.success) {
            this.alertError(res.error, 5000);
          }
      });
    }
  }

  changeMemberShopType(type) {
    if (this.member.shop[this.system.id].selected.type !== type && this.member.shop[this.system.id].list[type] !== undefined) {
      this.fns.httpsCallable('API')({ method: 'memberType', action: 'changeShop',
        shopType: type }).toPromise().then(res => {
          if (!res.success) {
            this.alertError(res.error, 5000);
          }
      });
    }
  }

  changeMemberShopId(id) {
    if (this.member.shop[this.system.id].list[this.member.shop[this.system.id].selected.type][id]) {
      this.fns.httpsCallable('API')({ method: 'memberType', action: 'changeShopId',
        shopType: this.member.shop[this.system.id].selected.type, id: `${id}` }).toPromise().then(res => {
          if (!res.success) {
            this.alertError(res.error, 5000);
          }
      });
    }
  }

}
