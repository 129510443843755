import { Component, OnInit } from '@angular/core';
import { UtilService } from './../util.service';

declare function initial(isDevMode: boolean): any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

  constructor(
    public util: UtilService
  ) { }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    initial(this.util.isDevMode);
  }

}
