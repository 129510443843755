import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';

import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';
import 'js-beautify';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { MomentModule } from 'ngx-moment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';

import { ChatComponent } from './chat/chat.component';
import { SalesComponent } from './sales/sales.component';
import { CustomerComponent } from './customer/customer.component';

import { ProductInfoComponent } from './product/info/info.component';
import { ProductInventoryComponent } from './product/inventory/inventory.component';

import { ReportSalesComponent } from './report/sales/sales.component';
import { ReportProfitComponent } from './report/profit/profit.component';
import { ReportPayTimeComponent } from './report/pay-time/pay-time.component';
import { ReportTransportationComponent } from './report/transportation/transportation.component';
import { ReportPaymentComponent } from './report/payment/payment.component';

import { SettingShopComponent } from './setting/shop/shop.component';
import { SettingPaymentComponent } from './setting/payment/payment.component';
import { SettingTransportationComponent } from './setting/transportation/transportation.component';
import { SettingPermissionComponent } from './setting/permission/permission.component';
import { SettingMessageComponent } from './setting/message/message.component';
import { SettingBillComponent } from './setting/bill/bill.component';

import { AdminShopComponent } from './admin/shop/shop.component';
import { AdminScreenComponent } from './admin/screen/screen.component';
import { AdminSystemComponent } from './admin/system/system.component';
import { AdminUserComponent } from './admin/user/user.component';

@NgModule({
  declarations: [
    AppComponent,

    LoginComponent,
    DashboardComponent,
    ProfileComponent,
    ChatComponent,
    SalesComponent,
    CustomerComponent,
    ProductInfoComponent,
    ProductInventoryComponent,
    ReportSalesComponent,
    ReportProfitComponent,
    ReportPayTimeComponent,
    ReportTransportationComponent,
    ReportPaymentComponent,
    SettingShopComponent,
    SettingPaymentComponent,
    SettingTransportationComponent,
    SettingPermissionComponent,
    SettingMessageComponent,
    SettingBillComponent,
    AdminShopComponent,
    AdminScreenComponent,
    AdminSystemComponent,
    AdminUserComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireModule,
    AngularFireFunctionsModule,
    AngularFireDatabaseModule,
    CodemirrorModule,
    NgxSmartModalModule.forRoot(),
    MomentModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
