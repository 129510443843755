import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilService } from './../../util.service';
import { FormInput } from './../../formInput';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare function initial(isDevMode: boolean): any;

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html'
})
export class AdminShopComponent implements OnInit, OnDestroy {

  constructor(
    private util: UtilService,
    private fns: AngularFireFunctions,
    private smartModal: NgxSmartModalService,
    private db: AngularFireDatabase
  ) {
    this.input.info = {
      id: '',
      name: '',
      detail: '',
      owner: ''
    };
  }

  input = new FormInput();
  inputType = '';
  action = '';
  currentData: any = {};
  isCheck: any = {
    headquarter: false,
    shop: false,
    supplier: false,
    warehouse: false
  };
  hasCheck: any = {
    headquarter: false,
    shop: false,
    supplier: false,
    warehouse: false
  };
  memberInfo: any = {};

  shop: any = null;
  subscription: any;

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    initial(this.util.isDevMode);
    this.subscribeShop();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  subscribeShop() {
    this.subscription = this.db.object(`shop/info/${this.util.system.id}`).valueChanges().subscribe(
      (res: any) => {
        this.shop = {};
        if (res !== null) {
          const types = Object.keys(res);
          types.forEach(key => {
            const arr = [];
            const systems = Object.keys(res[key]);
            systems.forEach(system => {
              arr.push( Object.assign({ id: system, checked: false }, res[key][system]) );
              if ( this.memberInfo[res[key][system].owner] === undefined ) {
                this.memberInfo[res[key][system].owner] = { name: res[key][system].owner !== undefined ? res[key][system].owner : '-' };
                if (res[key][system].owner !== undefined) { this.getMemberName(res[key][system].owner); }
              }
            });
            arr.sort( (a, b) => {
              return a.level - b.level;
            });
            this.shop[key] = arr;
          });
        }
      },
      error => {
        this.util.alertError(error, 5000);
      }
    );
  }

  getMemberName(id) {
    this.fns.httpsCallable('API')({ method: 'member', action: 'name', id: `${id}` })
    .toPromise()
    .then(res => {
      if (res.success) {
        this.memberInfo[id].name = res.result;
      } else {
        this.util.alertError(res.error, 5000);
      }
    });
  }

  addData(type) {
    this.inputType = type;
    this.action = 'add';
    this.input.info.id = '';
    this.input.info.name = '';
    this.input.info.detail = '';
    this.input.info.owner = '';
    this.currentData = { id: this.input.info.id, name: this.input.info.name,
      detail: this.input.info.detail, owner: this.input.info.owner };
    this.smartModal.getModal('inputModal').open();
  }

  editData(type, index) {
    this.inputType = type;
    this.action = 'update';
    this.input.info.id = this.shop[type][index].id;
    this.input.info.name = this.shop[type][index].name;
    this.input.info.detail = this.shop[type][index].detail;
    this.input.info.owner = this.shop[type][index].owner;
    this.currentData = { id: this.input.info.id, name: this.input.info.name,
      detail: this.input.info.detail, owner: this.input.info.owner };
    this.smartModal.getModal('inputModal').open();
  }

  saveData() {
    const json: any = {
      method: 'shop',
      action: this.action,
      type: this.inputType
    };

    let takeAction = false;
    const keys = Object.keys(this.currentData);
    keys.forEach(key => { // ตรวจสอบว่าข้อมูลที่เพิ่มหรือแก้ไข มีการเปลี่ยนแปลงหรือไม่
      if (this.currentData[key] !== this.input.info[key]) {
        json[key] = this.input.info[key];
        takeAction = true;
      }
    });
    if (this.action === 'add') {
      json.level = this.shop !== null && this.shop[this.inputType] !== undefined ? this.shop[this.inputType].length : 0;
    } else if (this.action === 'update') {
      json.id = this.input.info.id;
    }

    this.smartModal.getModal('inputModal').close();

    if (takeAction) {
      this.fns.httpsCallable('API')(json)
      .toPromise()
      .then(res => {
        if (!res.success) { this.util.alertError(res.error, 5000); }
      });
    }
  }

  selectData(type) {
    this.isCheck[type] = !this.isCheck[type];
    this.hasCheck[type] = this.isCheck[type];
    this.shop[type].forEach(element => {
      element.checked = this.isCheck[type];
    });
  }

  checkData(type) {
    let count = 0;
    this.shop[type].forEach(element => {
      if (element.checked) { count++; }
    });
    this.hasCheck[type] = count > 0;

    if (this.shop[type].length === count) {
      this.isCheck[type] = true;
    }
    if (count === 0) {
      this.isCheck[type] = false;
    }
  }

  removeData(type) {
    this.inputType = type;
    this.smartModal.getModal('confirmModal').open();
  }

  removeSelectedData() {
    this.smartModal.getModal('confirmModal').close();
    this.hasCheck[this.inputType] = false;
    this.shop[this.inputType].forEach(element => {
      if (element.checked) {
        this.fns.httpsCallable('API')({ method: 'shop', action: 'remove', type: this.inputType, id: element.id })
        .toPromise()
        .then(res => {
          if (!res.success) { this.util.alertError(res.error, 5000); }
        });
      }
    });
  }

}
