export const environment = {
  production: false,
  system : {
    id: 'shop online'
  },

  firebase: {
    apiKey: 'AIzaSyDzOdmMMDsfI1NjZKUi3mS9pMPnkjScG_M',
    authDomain: 'power-bolt-test.firebaseapp.com',
    databaseURL: 'https://power-bolt-test.firebaseio.com',
    projectId: 'power-bolt-test',
    storageBucket: 'power-bolt-test.appspot.com',
    messagingSenderId: '953347123654'
  }

};
