import { Component, OnInit } from '@angular/core';
import { UtilService } from './../../util.service';
import { FormInput } from './../../formInput';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare function initial(isDevMode: boolean): any;

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html'
})
export class AdminSystemComponent implements OnInit {

  constructor(
    private util: UtilService,
    private db: AngularFireDatabase,
    private smartModal: NgxSmartModalService
  ) { }

  input = new FormInput();
  hasCheck = false;
  isCheck = false;

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    initial(this.util.isDevMode);
    this.getSystemInfo();
  }

  getSystemInfo() {
    if (this.util.systemInfo !== undefined) {
      this.input.info = this.util.systemInfo;
    } else {
      setTimeout(() => {
        this.getSystemInfo();
      }, 1000);
    }
  }

  updateData(key) {
    this.db.object(`system/info/${this.util.system.id}/${key}`).set(this.input.info[key]);
  }

  addLanguage() {
    this.input.id = '';
    this.input.name = '';
    this.smartModal.getModal('inputModal').open();
  }

  selectData() {
    this.isCheck = !this.isCheck;
    this.hasCheck = this.isCheck;
    this.util.systemInfo.language.forEach(element => {
      element.checked = this.isCheck;
    });
  }

  checkData() {
    let count = 0;
    this.util.systemInfo.language.forEach(element => {
      if (element.checked) { count++; }
    });
    this.hasCheck = count > 0;

    if (this.util.systemInfo.language.length === count) {
      this.isCheck = true;
    }
    if (count === 0) {
      this.isCheck = false;
    }
  }

  swapLevel(value, index) {
    this.util.systemInfo.language[index].level = index + value;
    this.util.systemInfo.language[index + value].level = index;

    this.db.object(`system/info/${this.util.system.id}/language/${this.util.systemInfo.language[index].id}/level`).set(index + value);
    this.db.object(`system/info/${this.util.system.id}/language/${this.util.systemInfo.language[index + value].id}/level`).set(index);
  }

  saveLanguage() {
    this.smartModal.getModal('inputModal').close();
    this.db.object(`system/info/${this.util.system.id}/language/${this.input.id}`).set({
      name: this.input.name,
      level: this.util.systemInfo.language.length
    });
  }

  removeLanguage() {
    this.smartModal.getModal('confirmModal').open();
  }

  removeSelectedData() {
    this.smartModal.getModal('confirmModal').close();
    this.hasCheck = false;
    this.util.systemInfo.language.forEach(element => {
      if (element.checked) {
        this.db.object(`system/info/${this.util.system.id}/language/${element.id}`).remove();
      }
    });
  }

}
